import Vue from 'vue';
import { LIFECYCLE_STEPS, STEP_MAP } from './consts';
import { CHAIN } from '../../shared/util/chain-of-responsibility';
import { ERROR_CODES } from '../../shared/util/errors';

export function makeAppVm (app) {
  app.$services.register('crunch:app-vm', new Vue({
    data () {
      return {
        status: -1,
        errors: []
      };
    },

    computed: {
      isRunning () {
        return this.status === (LIFECYCLE_STEPS.length - 1);
      }
    },

    methods: {
      async moveNext () {
        if (!this.isRunning) {
          this.status++;
          const step = STEP_MAP[this.status];
          const result = await step(this.env);
          app.trigger(`lifecycle:${this.status}`);
          if (result !== CHAIN.STOP) {
            try {
              return await this.moveNext();
            } catch (ex) {
              console.error(ex);
              this.addError(ex.code);
              app.router.push({ name: 'crunch:startup:error' });
            }
          }
        }
      },

      start (env) {
        this.env = env;
        this.moveNext();
      },

      addError (code) {
        this.errors.push(code || ERROR_CODES.UNHANDLED_EXCEPTION);
      }
    }
  }));
  return app.$services.resolve('crunch:app-vm');
}
