<template>
  <router-link
    :to="webViewerLink"
    class="file__link"
  >
    <div
      class="d-flex align-items-center flex-column file__b-view"
      :id="`popover-${modelName}`"
    >
      <b-overlay
        :show="thumbnailImgLoading"
        rounded="sm"
        class="file__b-view_overlay"
      >
        <template #overlay>
          <lbs-progress-bar anchored />
        </template>

        <img
          :src="model.thumbnailUrl"
          :alt="$t('alt-icon-file')"
          @load="thumbnailImgLoading = false"
          v-show="!thumbnailImgLoading"
        >
      </b-overlay>
      <span>{{ modelName }}</span>
    </div>

    <file-popover
      :target="`popover-${modelName}`"
      :model="model"
    />
  </router-link>
</template>

<script>
import FilePopover from 'modules/gallery/components/FilePopover';
import { buildPureUrlViewer } from 'shared/util/buildPureUrlViewer';
import { camelWrap } from 'shared/util/camel-wrap';
import LbsProgressBar from 'lbs-pi-web-widgets-dev/lib/components/LbsProgressBar';

export default {
  name: 'FileTile',
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  components: {
    FilePopover,
    LbsProgressBar
  },
  watch: {
    'model.thumbnailUrl': function () {
      this.thumbnailImgLoading = true;
    }
  },
  computed: {
    webViewerLink () {
      return buildPureUrlViewer(this.model?.thumbnailUrl);
    },
    modelName () {
      return camelWrap(this.model.name);
    }
  },
  data () {
    return {
      thumbnailImgLoading: true
    };
  }
};
</script>

<style lang="scss">
@import '~lbs-pi-web-styles/lib/_variables';

.file {
  &__link {
    display: inline-block;
    width: 100%;
  }
  &__b-view {
    font-family: 'Roboto Condensed', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.188rem;
    text-align: center;
    color: $leica-warm-grey-dark;
    width: 100%;

    &_overlay {
      overflow: hidden;
      height: 60px;
      margin-bottom: 15px;

      .b-overlay {
        width: 100%;
        height: 100%;

        div {
          width: 100%;
          height: 100%;
        }
      }
    }
    & img {
      max-width: 100%;
      min-height: 60px;
      width: auto;
      height: auto;
    }
    & span {
      display: inline-block;
      width: 100%;
      word-wrap: break-word;
    }
  }
}
</style>
