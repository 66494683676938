<template>
  <simple-layout>
    <b-overlay
      :show="loading"
      rounded="sm"
    >
      <template #overlay>
        <lbs-progress-bar />
      </template>

      <div v-show="!loading">
        <breadcrumb
          v-if="breadcrumb.length !== 1"
          :items="breadcrumb"
        />

        <div class="gallery-head d-flex justify-content-between align-items-center">
          <h3 class="gallery-head__title">
            {{ nameOfCurrentDir }}
          </h3>
          <b-button
            class="gallery-head__btn-switch"
            @click="switchView"
          >
            <img
              :src="switchBtnData.src"
              :alt="$t(switchBtnData.alt)"
            >
          </b-button>
        </div>

        <tile-view-container
          v-if="galleryView === 'tile'"
          :data="{ folders, files }"
          :folder-is-empty="folderIsEmpty"
        />
        <table-view-container
          v-else-if="galleryView === 'table'"
          :data="{ folders, files }"
          :folder-is-empty="folderIsEmpty"
        />
      </div>
    </b-overlay>
  </simple-layout>
</template>

<script>
import SimpleLayout from 'shared/components/SimpleLayout';
import Breadcrumb from 'shared/components/Breadcrumb';
import TileViewContainer from 'modules/gallery/components/TileViewContainer';
import TableViewContainer from 'modules/gallery/components/TableViewContainer';
import LbsProgressBar from 'lbs-pi-web-widgets-dev/lib/components/LbsProgressBar';

import {
  mapState,
  mapGetters
} from 'vuex';

/**
 * @todo
 * add an watcher for the 'path' variable to request file nesting of the current route
 * use 'loading' variable
 */
export default {
  name: 'GalleryContainer',
  components: {
    SimpleLayout,
    Breadcrumb,
    TileViewContainer,
    TableViewContainer,
    LbsProgressBar
  },
  data () {
    return {
      galleryView: 'table'
    };
  },
  mounted () {
    this.initGalleryView();
  },
  computed: {
    ...mapState([
      'path',
      'files',
      'folders',
      'loading'
    ]),
    ...mapGetters([
      'breadcrumb'
    ]),
    switchBtnData () {
      if (this.galleryView === 'tile') {
        return {
          src: '/static/images/switch-to-line.svg',
          alt: 'alt-switch-icon-to-table'
        };
      } else {
        return {
          src: '/static/images/switch-to-tile.svg',
          alt: 'alt-switch-icon-to-tile'
        };
      }
    },
    folderIsEmpty () {
      return !this.folders?.length && !this.files?.length;
    },
    nameOfCurrentDir () {
      const { params } = this.$route;

      if (params && Object.keys(params)?.length && params.path) {
        const pathArr = params.path.split('/');

        return pathArr[pathArr.length - 1];
      } else {
        return this.$t('all-files');
      }
    }
  },
  methods: {
    initGalleryView () {
      if (!localStorage.getItem('galleryView')) {
        localStorage.setItem('galleryView', 'table');
      }

      this.galleryView = localStorage.getItem('galleryView');
    },
    switchView () {
      if (this.galleryView === 'tile') {
        this.galleryView = 'table';
      } else if (this.galleryView === 'table') {
        this.galleryView = 'tile';
      }

      localStorage.setItem('galleryView', this.galleryView);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~lbs-pi-web-styles/lib/_variables';

.gallery-head {
  &__title {
    color: $leica-warm-grey-dark;
    margin: 0;
  }
  &__btn-switch {
    padding: 8px 6px;
    background: $leica-warm-grey-light;

    &>img {
      width: 20px;
      height: 16px;
    }
  }
}
</style>
