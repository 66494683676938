import { makeAppVm } from './app-vm';
import { makeStore } from 'shared/store';
import { GalleryProvider } from '@/modules/gallery/data-providers/GalleryProvider';

export function addLifecycleMethods (app) {
  app.start = async function (AppContainer) {
    const api = new GalleryProvider();

    const env = {
      app,
      AppContainer,
      hammer: null,
      authConfig: null,
      appVm: makeAppVm(app),
      store: makeStore(api)
    };

    env.appVm.start(env);
  };
}
