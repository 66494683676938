<template>
  <simple-layout>
    <b-row>
      <b-col>
        <breadcrumb
          v-if="breadcrumb.length !== 1"
          :items="breadcrumb"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <p>
          You have clicked to view <b>{{ filePath }}</b> with the ImageScope viewer.
          <br>
          If ImageScope is installed, double-click the downloaded file to open it.
        </p>
        <p>
          ImageScope is available as a free download and installs easily on any Windows PC.
        </p>
        <p>
          <b-link
            href="https://www.leicabiosystems.com/digital-pathology/manage/aperio-imagescope/"
            target="_blank"
          >
            Download and install ImageScope
          </b-link>. After you have installed ImageScope you may click on the
          following link to view the digital slide.
        </p>
        <hr>
        <b-row>
          <b-col
            sm="12"
            md="6"
          >
            The ImageScope viewer includes features
            such as:
            <ul>
              <li>Ability to view multiple slides concurrently, and to synchronize panning and zooming.</li>
              <li>Modify color balance</li>
              <li>Participate in digital slide conferences.</li>
              <li>Generally faster and more responsive.</li>
            </ul>
            <p>
              Please visit the <b-link
                href="https://www.leicabiosystems.com/digital-pathology/manage/"
                target="_blank"
              >
                Aperio
              </b-link>
              website for more information.
            </p>
          </b-col>
          <b-col
            class="d-flex justify-content-center justify-content-md-end"
            sm="12"
            md="6"
          >
            <b-link
              href="https://www.leicabiosystems.com/digital-pathology/manage/aperio-imagescope/"
              target="_blank"
            >
              <img
                src="/static/images/ImageScope_small.jpg"
                width="300"
                alt="imageScope"
              >
            </b-link>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </simple-layout>
</template>

<script>
import { mapGetters } from 'vuex';

import { makeSisFile } from 'modules/image-scope/sisFile';

import Breadcrumb from 'shared/components/Breadcrumb';
import SimpleLayout from 'shared/components/SimpleLayout';

export default {
  name: 'SisFileContainer',

  components: {
    Breadcrumb,
    SimpleLayout
  },

  computed: {
    ...mapGetters([
      'breadcrumb'
    ]),

    filePath () {
      return this.$route.path.replace('/imagescope', '');
    },
    fileName () {
      const { filePath } = this;

      return filePath.slice(filePath.lastIndexOf('/') + 1);
    },
    contents () {
      const path = this.$route.path.toLowerCase().replace('/imagescope', '');
      const args = Object
        .entries(this.$route.query)
        .reduce((obj, [key, value]) => {
          obj[key.toLowerCase()] = value;
          return obj;
        }, {});

      return makeSisFile({
        ...args,
        path
      });
    }
  },

  mounted () {
    const a = document.createElement('a');
    const blob = new Blob([this.contents], { type: 'application/octet-stream' });
    a.classList.add('image-scope-download-link');
    a.setAttribute('href', window.URL.createObjectURL(blob));
    a.setAttribute('download', `${this.fileName}.sis`);
    a.style.display = 'none';
    document.body.appendChild(a); // required by firefox
    a.click();
    document.body.removeChild(a);
  }
};
</script>

<style lang="scss" scoped>

</style>
