<template>
  <b-table-simple class="table table-view-container">
    <b-thead>
      <b-tr>
        <b-th scope="col">
          {{ $t('name') }}
        </b-th>
        <b-th scope="col">
          {{ $t('dimensions') }}
        </b-th>
        <b-th scope="col">
          {{ $t('label') }}
        </b-th>
        <b-th scope="col">
          {{ $t('macro') }}
        </b-th>
        <b-th scope="col">
          {{ $t('title') }}
        </b-th>
        <b-th scope="col">
          {{ $t('date') }}
        </b-th>
        <b-th scope="col">
          {{ $t('time') }}
        </b-th>
        <b-th scope="col">
          {{ $t('quality') }}
        </b-th>
        <b-th scope="col">
          {{ $t('file') }}
        </b-th>
      </b-tr>
    </b-thead>
    <b-tbody
      v-if="folderIsEmpty"
      class="folder-is-empty"
    >
      <b-tr>
        <b-td
          colspan="13"
        >
          <folder-empty />
        </b-td>
      </b-tr>
    </b-tbody>
    <b-tbody v-else>
      <b-tr
        v-for="(folder, i) in data.folders"
        :key="`folder-${i}`"
      >
        <b-td colspan="13">
          <folder
            :model="folder"
            :table-view="true"
          />
        </b-td>
      </b-tr>
      <file-table
        v-for="(file, i) in data.files"
        :key="`file-${i}`"
        :model="file"
        :table-view="true"
      />
    </b-tbody>
  </b-table-simple>
</template>

<script>
import Folder from 'modules/gallery/components/Folder';
import FileTable from 'modules/gallery/components/FileTable';
import FolderEmpty from 'modules/gallery/components/FolderEmpty';

export default {
  name: 'TableViewContainer',
  i18nOptions: {
    keyPrefix: 'modules.gallery.table-view-head'
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    folderIsEmpty: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Folder,
    FileTable,
    FolderEmpty
  }
};
</script>

<style lang="scss">
@import '~lbs-pi-web-styles/lib/_variables';

.table-view-container {
  margin-top: 22px;

  thead {
    background: $leica-warm-grey-dark;

    tr {
      th {
        padding: 6px 12px;
        font-family: 'Roboto Condensed', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.188rem;
        color: $leica-white;
        border-bottom: none;

        &:first-child {
          padding-left: 24px;
        }
        &:last-child {
          padding-right: 24px;
        }
      }
    }
  }
  tbody {
    tr:first-child {
      td {
        padding-top: 24px;
      }
    }
    td, th {
      padding: 16px 12px;

      &:first-child {
        padding-left: 24px;
      }
      &:last-child {
        padding-right: 24px;
      }
    }
    &.folder-is-empty {
      td, th {
        border-bottom: none;
      }
    }
  }
  td, th {
    border-top: none;
    border-bottom: 1px solid $leica-warm-grey-light-dark;
  }
}
</style>
