<template>
  <div class="app-container">
    <site-header :title="$t('title')" />

    <div class="site-content">
      <div class="app-content-container">
        <!-- note that including <transition> here causes some webviewer styling to fail due to uScope rectcompute() being called before the transition ends -->
        <router-view />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import SiteHeader from '../../shared/components/SiteHeader.vue';

export default {
  name: 'AppContainer',

  components: {
    SiteHeader
  },
  provide () {
    const appVm = this.$services.resolve('crunch:app-vm');
    const configVm = {}; // TBD

    return { appVm, configVm };
  },

  mounted () {
    document.title = this.$t('document-title');
  }
};
</script>

<style lang="scss">
@import "../../shared/styles/global-styles";
</style>

<style lang="scss" scoped>
@import "../../shared/styles/app-variables";

/* layout for UI:
                                     container -  display: flex
 ┎──────────────────────────────────────────────┒ Header:
 │         Fixed size & position header         │   position: sticky
 │         Fixed size & position header         │   overflow: visible
 ┠──────────────────────────────────────────────┨   flex-grow: 0
 │                                              │
 │                                              │ Content:
 │                                              │   flex-grow: 1
 │           Variable height content            │   height: 100%
 │                                              │
 └──────────────────────────────────────────────┚
*/

.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
}

.site-content,
.app-content-container {
  height: 100%;
  flex-grow: 1;
}
</style>
