export async function configureI18n (app) {
  const { Hammer } = app;
  const config = window.leicaPi;
  const savedLocale = localStorage.getItem('locale');

  Hammer.config.i18n.moduleLocation = `${process.env.BASE_URL}${Hammer.config.i18n.moduleLocation.substring(1)}`;
  Hammer.i18n.addNamespaces(['gallery-main', 'webviewer']);
  Hammer.config.i18n.defaultModule = ['gallery-main', 'webviewer'];
  Hammer.config.i18n.defaultLanguage = savedLocale || config.i18n.language;

  // Expose the Vue router and i18next to the app and services
  app.i18n = await app.Hammer.i18n.init();
  app.$t = (...args) => app.i18n.i18next.t.apply(app.i18n.i18next, args);

  // hack for webviewer
  window.i18next = app.i18n.i18next;
}
