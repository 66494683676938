<template>
  <div :class="['folder', { 'table-view': tableView }]">
    <router-link
      :to="{ path: model.name }"
      append
    >
      <img
        src="/static/images/folder-with-files.svg"
        :alt="$t('alt-icon-folder')"
        class="folder__icon"
      >
      <span class="folder__title">{{ modelName }}</span>
    </router-link>
  </div>
</template>

<script>
import { camelWrap } from 'shared/util/camel-wrap';

export default {
  name: 'Folder',
  i18nOptions: {
    keyPrefix: 'modules.gallery'
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    tableView: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    modelName () {
      return camelWrap(this.model.name);
    }
  }
};

</script>

<style lang="scss" scoped>
@import '~lbs-pi-web-styles/lib/_variables';

.folder {

  &>a {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 104px;
  }
  &__icon {
    margin-bottom: 12px;
    width: 48px;
    height: 39px;
  }
  &__title {
    font-family: Roboto Condensed, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.188rem;
    text-align: center;
    color: $leica-warm-grey-dark;
    overflow-wrap: anywhere;
  }

  &.table-view {
    .folder {
      &__icon {
        width: 24px;
        height: 20px;
        margin-right: 16px;
        margin-bottom: 0;
      }
    }
    a {
      width: auto;
      flex-direction: row;
      align-items: center;
    }
  }
}
</style>
