<template>
  <div class="lbs-scope-container">
    <div id="uscope" />
  </div>
</template>

<script lang="ts">
// bloated uScope vendor chunk
import 'lbs-pi-web-viewer/dist/lib/vendor-shared.min';
import 'lbs-pi-web-viewer/dist/lib/vendor-uscope.min';

// uScope core
import { UScopeLauncher } from 'lbs-pi-web-viewer/src/uScope/js/public-api';

// interactivity
import 'lbs-pi-web-viewer/src/uScope/js/hmi/keyboard';
import 'lbs-pi-web-viewer/src/uScope/js/hmi/pointer/pointer-loader';

// overlays
import 'lbs-pi-web-viewer/src/uScope/js/extensions/uscope-toolbar';
import 'lbs-pi-web-viewer/src/uScope/js/extensions/unit-converter';
import 'lbs-pi-web-viewer/src/uScope/js/extensions/image-toolbar';
import 'lbs-pi-web-viewer/src/uScope/js/extensions/label';
import 'lbs-pi-web-viewer/src/uScope/js/extensions/magnifier';
import 'lbs-pi-web-viewer/src/uScope/js/extensions/rotation-dial';
import 'lbs-pi-web-viewer/src/uScope/js/extensions/scalebar';
import 'lbs-pi-web-viewer/src/uScope/js/extensions/settings-services';
import 'lbs-pi-web-viewer/src/uScope/js/extensions/thumbnail';
import 'lbs-pi-web-viewer/src/uScope/js/extensions/user-config';
import 'lbs-pi-web-viewer/src/uScope/js/extensions/zoom';

// other extensions
import 'lbs-pi-web-viewer/src/uScope/js/extensions/auto-pan';
import 'lbs-pi-web-viewer/src/uScope/js/extensions/image-adjust';
import 'lbs-pi-web-viewer/src/uScope/js/extensions/snapshot';
import 'lbs-pi-web-viewer/src/uScope/js/extensions/status-bar';
import 'lbs-pi-web-viewer/src/uScope/js/extensions/focus';

export default {
  name: 'ViewerContainer',

  i18nOptions: {
    keyPrefix: 'modules.viewer'
  },

  computed: {
    path () {
      let path = window.location.pathname.replace('/view', '');
      if (!path) {
        path = '/';
      }
      return path.replace(/^\/|\/$/g, '');
    }
  },

  mounted () {
    this.startUScope();
  },

  methods: {
    startUScope () {
      const path = this.path;
      UScopeLauncher.i18nInit();
      const uScope = new UScopeLauncher();
      uScope.addSlideInfo(path);
      uScope.mount('#uscope');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~lbs-pi-web-viewer/dist/lib/vendor-shared.min.css';
@import '~lbs-pi-web-viewer/dist/lib/lbs.pi.webViewer.min.css';

#uscope {
  width: 100vw;
  height: calc(100vh - 60px);
}

body {
  margin: 0;
  padding: 0;
}

#uscope {
  position: relative;
  overflow: hidden;
  float: left;
}

.lbs-scope-container {
  @import '~lbs-pi-web-styles/dist/css/bootstrap';
  @import '~bootstrap-vue/dist/bootstrap-vue';
}

</style>
