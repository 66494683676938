<template>
  <div class="simple-container">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'SimpleLayout'
};
</script>

<style scoped lang="scss">
@import '~lbs-pi-web-styles/lib/_variables';
@import '../styles/app-variables';

.simple-container {
  $padding-top: 24px;
  $height: calc(100vh - #{$site-header-height} - #{$padding-top} * 2);
  padding: $padding-top 29px;
  max-width: 1264px;
  min-height: $height;
  max-height: $height;
  overflow: auto;
  margin: 40px auto 0;
  background: $leica-white;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
}
</style>
