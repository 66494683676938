export function makeGetters () {
  return {
    /**
     * converts the current `path` into an array of breadcrumb items
     *
     * @Input 'dir/file'
     * @Output
     * [
     *   {
     *     label: 'dir',
     *     href: '/dir'
     *   },
     *   {
     *     label: 'file',
     *     href: '/dir/file'
     *   }
     * ]
     */
    breadcrumb ({ path }) {
      const result = [
        {
          label: 'All Files',
          href: '/'
        }
      ];

      path
        .split('/')
        .reduce((acc, current) => {
          const href = `${acc + current}`;
          if (current) {
            result.push({ label: current, href });
          }
          return `${href}/`;
        }, '');

      return result;
    }
  };
}
