import { iterableEnum } from '../../shared/util/enum';
import { init } from './initialize';
import { mount } from './mount';
import { restoreAppState } from './restore-app-state';

// Mostly used for i18n
export const LIFECYCLE_STEPS = iterableEnum(
  'INITIALIZING',
  'MOUNTING',
  'RUNNING'
);

// Maps step names to their operations. These are used by the appvm to walk the startup sequence.
export const STEP_MAP = {
  [LIFECYCLE_STEPS.INITIALIZING]: init,
  [LIFECYCLE_STEPS.MOUNTING]: mount,
  [LIFECYCLE_STEPS.RUNNING]: restoreAppState
};
