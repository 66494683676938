import GalleryContainer from './GalleryContainer.vue';

export function initGallery (app) {
  app.routing.addRoutes({
    path: '/:path*',
    name: 'galleryRoot',
    component: {
      components: { GalleryContainer },
      template: '<gallery-container/>',
      i18nOptions: {
        keyPrefix: 'modules.gallery'
      },
      beforeRouteEnter ({ params }, from, next) {
        /**
           * The `beforeRouteEnter` guard does NOT have access to `this` component instance
           * access to component instance via `vm`
           */
        next(vm => {
          vm.$store.commit('updatePath', params.path);
          vm.$store.dispatch('getGalleryItems', params.path);
        });
      },
      beforeRouteUpdate ({ params }, from, next) {
        this.$store.commit('updatePath', params.path);
        this.$store.dispatch('getGalleryItems', params.path);
        next();
      }
    }
  }
  );
}
