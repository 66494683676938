<template>
  <header class="site-header">
    <b-navbar
      class="nav-bar"
      type="dark"
      variant="dark"
      toggleable="lg"
    >
      <b-navbar-brand>
        <router-link :to="{ name: 'galleryRoot' }">
          <lbs-product-name
            :product-name="$t('gallery-main:application-name')"
            :brand-name="$t('gallery-main:brand-name')"
            theme="light"
            class="site-header-color"
          />
        </router-link>
      </b-navbar-brand>
      <b-navbar-toggle target="nav_collapse" />
      <b-collapse
        is-nav
        class="nav_collapse"
        id="nav_collapse"
      >
        <b-navbar-nav
          class="app-links"
        />

        <img
          src="/static/images/leica-brand.svg"
          class="leica-brand"
          :alt="$t('company-name')"
          @click="showHelp = !showHelp"
        >
      </b-collapse>
    </b-navbar>
    <info-slider :show="showHelp" />
  </header>
</template>

<script>
import LbsProductName from 'lbs-pi-web-widgets/lib/components/LbsProductName';
import InfoSlider from './info-slider/Slider';

export default {
  components: {
    LbsProductName,
    InfoSlider
  },

  i18nOptions: {
    namespaces: 'gallery-main'
  },

  data () {
    return {
      showHelp: false
    };
  },

  computed: {
    appVersion () {
      return '2.0.0'; // TODO: Update on build
    }
  },

  mounted () {
    document.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        this.showHelp = false;
      }
    });
  }
};
</script>

<style lang="scss"
       scoped>
@import '~lbs-pi-web-styles/lib/_variables';
@import "../styles/app-variables";

.site-header {
  flex-grow: 0;
  height: $site-header-height;
  overflow: visible;
  position: sticky;
  z-index: 100; // Site header should generally be above other UI elements except overlays

  // Grey band
  background-color: $leica-warm-grey-dark;

  font-size: 12px;
}

// All nav items should fill the header completely
.nav-bar {
  height: 100%;
  // these padding rules override bootstrap's and allow us to have the logo flush against the container
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;

  .nav_collapse {
    height: 100%; // fixes brand image size overflow
  }

  // ...but no more than 100%
  .app-link {
    max-height: 100%;

    img {
      max-height: 28px;
      max-width: 35px;
    }
  }
}

// Left links
.app-links {
  // should take up the bulk of the space
  flex-grow: 1;

  .nav-link {
    // Should be about square
    height: 100%;
    min-width: 100px;

    // Text should be centered above the icon
    text-align: center;
  }

  // Background should be red when active
  .active {
    background: $leica-red;
    color: $leica-white;
  }
}

// Center the title/user area vertically
.control-panel > li {
  margin-top: auto;
  margin-bottom: auto;
}

// The image should be right-aligned with no horizontal or vertical gaps. Since we're height constrained have it
// horizontally fill and let the browser compute the width accordingly.
.leica-brand {
  height: 100%;
  width: auto;
  // Since it's clickable give it the finger
  cursor: pointer;
}
</style>

<style lang="scss">
@import '~lbs-pi-web-styles/lib/_variables';

.user-dropdown ul {
  margin-left: -20px;
}

.site-header-color {
  color: $leica-white !important;
}

.control-panel {
  font-size: 0.9rem;

  .nav-link {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }

  .btn-link {
    color: $leica-warm-grey-light-dark;
    padding: 0;

    &:hover {
      color: $leica-warm-grey-light-dark;
      text-decoration: none;
    }
  }

  .dropdown-menu {
    width: 146px;
    margin-top: 11px;
    padding: 1px 0;
    display: block;
    visibility: hidden;
    opacity: 0;
    border: 1px solid $leica-grey-light;
    border-radius: 0;
    font-size: 14px;

    &.show {
      opacity: 1;
      visibility: visible;
    }

    &::before {
      content: '';
      position: absolute;
      top: -8px;
      left: 50%;
      width: 13px;
      height: 13px;
      margin: 0 0 0 -6px;
      background-color: $leica-white;
      border-left: 1px solid $leica-grey-light;
      border-top: 1px solid $leica-grey-light;
      transform: rotate(45deg);
    }

    .dropdown-divider {
      margin-left: 14px;
      margin-right: 14px;
    }

    a {
      padding: 8px 15px;
      text-decoration: none;
      color: $leica-warm-grey-dark;

      &:hover {
        color: $leica-red;
        background: 0 0;
      }
    }
  }
}
</style>
