<template>
  <b-popover
    :target="target"
    triggers="hover focus"
    placement="auto"
    class="file-popover"
  >
    <div class="file-popover__head">
      <b-row>
        <b-col
          cols="4"
          class="file-popover__head-title"
        >
          {{ modelName }}
        </b-col>
        <b-col
          cols="8"
          class="file-popover__head-view d-flex align-items-center"
        >
          <span>{{ $t('view') }}:</span>
          <router-link :to="webViewerLink">
            {{ $t('web-viewer') }}
          </router-link>
          <router-link :to="imageScopeLink">
            {{ $t('image-scope') }}
          </router-link>
        </b-col>
      </b-row>
    </div>
    <div class="file-popover__body">
      <b-row>
        <b-col
          cols="3"
          class="file-popover__body-dimensions"
        >
          {{ parseDesc.dimensions }}
        </b-col>
        <b-col
          cols="3"
          class="file-popover__body-label d-flex justify-content-between flex-column"
        >
          <b-overlay
            :show="labelImgLoading"
            rounded="sm"
            class="file-popover__body-label-overlay"
          >
            <template #overlay>
              <lbs-progress-bar anchored />
            </template>
            <img
              :src="model.labelUrl"
              :alt="$t('alt-icon-file-label')"
              @load="labelImgLoading = false"
              v-show="!labelImgLoading"
            >
          </b-overlay>
          <p>{{ $t('label') }}</p>
        </b-col>
        <b-col
          cols="6"
          class="file-popover__body-macro d-flex justify-content-between flex-column"
        >
          <b-overlay
            :show="macroImgLoading"
            rounded="sm"
            class="file-popover__body-macro-overlay"
          >
            <template #overlay>
              <lbs-progress-bar anchored />
            </template>
            <img
              :src="model.macroUrl"
              :alt="$t('alt-icon-file-macro')"
              @load="macroImgLoading = false"
              v-show="!macroImgLoading"
            >
          </b-overlay>
          <p>{{ $t('macro') }}</p>
        </b-col>
      </b-row>
    </div>
    <div class="file-popover__footer">
      <b-row>
        <b-col
          cols="3"
          class="file-popover__footer-title"
        >
          {{ model.title }}
        </b-col>
        <b-col
          cols="9"
          class="file-popover__footer-attributes"
        >
          <template v-if="model.date">
            <span>{{ $t('popover-attributes.date') }}</span>
            <span>{{ model.date }}</span>
          </template>
          <template v-if="model.time">
            <span>{{ $t('popover-attributes.time') }}</span>
            <span>{{ model.time }}</span>
          </template>
          <template v-if="parseDesc.quality">
            <span>{{ $t('popover-attributes.quality') }}</span>
            <span>{{ parseDesc.quality }}</span>
          </template>
          <template v-if="fileSize">
            <span>{{ $t('popover-attributes.file-size') }}</span>
            <span>{{ fileSize }}</span>
          </template>
        </b-col>
      </b-row>
    </div>
  </b-popover>
</template>

<script>
import LbsProgressBar from 'lbs-pi-web-widgets-dev/lib/components/LbsProgressBar';
import { readableBytes } from 'shared/util/readableBytes';
import { parseTableAttributes } from 'shared/util/parseTableAttributes';
import { buildPureUrlViewer } from 'shared/util/buildPureUrlViewer';
import { buildPureUrlImageScope } from 'shared/util/buildPureUrlImageScope';
import { camelWrap } from 'shared/util/camel-wrap';

export default {
  name: 'FilePopover',
  props: {
    model: {
      type: Object,
      required: true
    },
    target: {
      type: String,
      required: true
    }
  },
  components: {
    LbsProgressBar
  },
  watch: {
    'model.labelUrl': function () {
      this.labelImgLoading = true;
    },
    'model.macroUrl': function () {
      this.macroImgLoading = true;
    }
  },
  data () {
    return {
      labelImgLoading: true,
      macroImgLoading: true
    };
  },
  computed: {
    fileSize () {
      return readableBytes(this.model.size);
    },
    parseDesc () {
      return parseTableAttributes(this.model.description);
    },
    webViewerLink () {
      return buildPureUrlViewer(this.model?.thumbnailUrl);
    },
    imageScopeLink () {
      return buildPureUrlImageScope(this.model?.thumbnailUrl);
    },
    modelName () {
      return camelWrap(this.model.name);
    }
  }
};
</script>

<style lang="scss">
@import '~lbs-pi-web-styles/lib/_variables';

.file-popover {
  &__head {
    padding: 15px 16px 11px 12px;
    border-bottom: 1px solid $leica-warm-grey-light;

    &-title {
      font-size: 1rem;
      line-height: 1.188rem;
      color: $leica-warm-grey-dark;
    }
    &-view {
      color: $leica-warm-grey-dark;

      span {
        margin-right: 8px;
      }

      a {
        &:after {
          content: '|';
          color: $leica-warm-grey-dark;
          margin-right: 5px;
        }

        &:last-child {
          margin-right: 0;

          &:after {
            display: none;
          }
        }
      }
    }
  }
  &__body {
    padding: 7px 16px 8px 12px;
    border-bottom: 1px solid $leica-warm-grey-light;

    &-dimensions {
      font-weight: 300;
      line-height: 1.125rem;
      color: $leica-warm-grey-dark;
    }
    &-label, &-macro {
      font-weight: 300;
      line-height: 1.125rem;
      color: $leica-black;

      p {
        margin: 0;
        text-transform: lowercase;
      }
      &-overlay {
        .b-overlay {
          width: 100%;
          height: 100%;

          div {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    &-label {
      &-overlay {
        border-radius: 2px;
        width: 60px;
        min-height: 60px;
        margin-bottom: 4px;
      }
      img {
        border-radius: 2px;
        overflow: hidden;
        width: 100%;
        height: auto;
      }
    }
    &-macro {
      &-overlay {
        border-radius: 2px;
        width: 106px;
        min-height: 60px;
        margin-bottom: 4px;
      }
      img {
        border-radius: 2px;
        overflow: hidden;
        width: 100%;
        height: auto;
      }
    }
  }
  &__footer {
    padding: 10px 12px;

    &-title {
      font-size: 1rem;
      line-height: 1.188rem;
      color: $leica-warm-grey-dark;
    }
    &-attributes {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      span:nth-of-type(2n + 1) {
        font-weight: bold;
      }
    }
  }
}
.popover {
  background: $leica-white;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  border: none;
  padding: 0;
  min-width: 357px;
}
.popover-body {
  padding: 0;
}
.arrow::before {
  border-left-color: rgba(0, 0, 0, 0.1) !important;
}
</style>
