import axios from 'axios';
import { ImageServerClient } from 'lbs-pi-platform-access/src/image-server/core/ImageServerClient';
import { DirectoryProvider } from 'lbs-pi-platform-access/src/image-server/file-system/DirectoryProvider';
import { ImageProvider } from 'lbs-pi-platform-access/es/image-server/image/ImageProvider.js';

export class GalleryProvider {
  #directoryProvider;
  #imageProvider;

  constructor () {
    const client = new ImageServerClient(axios);
    this.#directoryProvider = new DirectoryProvider(client);
    this.#imageProvider = new ImageProvider(client);
  }

  getGalleryItems (query = '/') {
    return this.#directoryProvider.list(query);
  }

  getImageUrls ({ path }) {
    const imageSize = 200;
    const thumbnailUrl = this.#imageProvider.getThumbnailUrl({ path }, imageSize);
    const labelUrl = this.#imageProvider.getLabelUrl({ path }, imageSize);
    const macroUrl = this.#imageProvider.getMacroUrl({ path }, imageSize);

    return {
      thumbnailUrl,
      labelUrl,
      macroUrl
    };
  }
}
