<template>
  <simple-layout>
    <header><h2>{{ $t('header-text') }}</h2></header>
    <div class="outer d-flex justify-content-center align-items-center">
      <div class="w-50 text-center">
        <label class="mr-2">{{ $t('status-label') }}</label>
        <span>{{ $t(`status-text.${stepName}`) }} {{ $t('status-number', lookup) }}</span>
        <b-progress
          class="lock-bottom"
          height="5px"
          :value="progress"
        />
      </div>
    </div>
  </simple-layout>
</template>

<script>
import { LIFECYCLE_STEPS } from '../../core/lifecycle/consts';
import SimpleLayout from '../../shared/components/SimpleLayout';

export default {
  name: 'Startup',

  i18nOptions: {
    keyPrefix: 'modules.startup'
  },

  components: { SimpleLayout },

  computed: {
    lookup () {
      return {
        stepNumber: this.stepNumber,
        numberOfSteps: this.numberOfSteps
      };
    },
    stepNumber () {
      return this.appVm.status;
    },
    stepName () {
      return LIFECYCLE_STEPS[this.stepNumber];
    },
    numberOfSteps () {
      return LIFECYCLE_STEPS.length - 1;
    },
    progress () {
      return this.stepNumber / this.numberOfSteps * 100;
    }
  },

  inject: ['appVm'],
  mounted () {
    this.$bvModal.show('modal-1');
  }
};
</script>

<style scoped
       lang="scss">
@import "../../shared/styles/app-variables";

.outer {
  $some-extra: 150px;
  height: calc(100vh - #{$site-header-height + $subheader-height + $some-extra});
}
</style>
