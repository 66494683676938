<template>
  <simple-layout>
    <header>
      <h2>{{ $t('title') }}</h2>
    </header>
    <section>
      <b-alert
        v-for="error in appVm.errors"
        :key="error"
        show
        variant="danger"
      >
        {{ $t(`gallery-main:server-errors.${error}`) }}
      </b-alert>
    </section>
    <section>
      {{ $t('additional-support-message') }}
    </section>
  </simple-layout>
</template>

<script>
import SimpleLayout from '../../shared/components/SimpleLayout';
export default {
  name: 'ErrorReport',
  components: { SimpleLayout },
  i18nOptions: {
    keyPrefix: 'modules.startup.errors'
  },

  created () {
    this.appVm = this.$services.resolve('crunch:app-vm');
  }
};
</script>

<style scoped lang="scss">
</style>
