import Viewer from './ViewerContainer.vue';

export function initViewerModule (app) {
  app.routing.addRoutes(
    {
      path: '/view/*',
      name: 'viewer',
      component: Viewer
    });
}
